import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const MinskMazowiecki23BLOT = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="23. Baza Lotnictwa Taktycznego im. ppłk. pil. Jana Zumbacha" />
      <ReturnToPrevPage to={'/gminy/minsk-mazowiecki'}>
        Materiały dotyczące miasta Mińsk Mazowiecki oraz otaczającej go gminy
      </ReturnToPrevPage>
      <Header1>
        23. Baza Lotnictwa Taktycznego im. ppłk. pil. Jana Zumbacha
      </Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <VideoPlayer videoId={896567775} />
        <p>
          Warto podkreślić, że zmodernizowana sala tradycji 23 Bazy Lotniczej
          została oddana do użytku 24 maja 2019 roku. Wtedy to na terenie
          jednostki odbyła się uroczystość nadania 23 BLT imienia patrona
          podpułkownika pilota Jana Zumbacha oraz odsłonięcia kamienia
          pamiątkowego poświęconego temu dowódcy legendarnego Dywizjonu 303 oraz
          133 Polskiego Skrzydła Myśliwskiego. Zauważmy, że osobie patrona
          poświęcona jest jedna z plansz znajdujących się w omawianym
          pomieszczeniu. Zawiera ona przede wszystkim rys biograficzny
          bohaterskiego pilota. - Przed II wojną światową służył w 111. eskadrze
          myśliwskiej 1. Pułku Lotniczego w Warszawie. Na skutek wypadku podczas
          lądowania w maju 1939 roku trafił do szpitala, a następnie przeszedł
          rehabilitację.
        </p>
        <p>
          Po wybuchu wojny nie udało mu się odnaleźć swojej jednostki i 17
          września przedostał się z Polski do Rumunii. Następnie
          przez Bejrut dotarł drogą morską do Francji. Po przybyciu do Francji,
          tak jak pozostali polscy lotnicy, został skierowany do Centrum
          Wyszkolenia Lotnictwa Polskiego w bazie Lyon-Bron. 13 maja 1940 został
          przydzielony do I. Klucza Kominowego chroniącego lotniska w Châteaudun
          i Étampes. Do spotkań z nieprzyjacielem dochodziło jednak rzadko i
          Zumbach nie odniósł tam sukcesów bojowych. 21 czerwca został
          ewakuowany do Wielkiej Brytanii – dowiadujemy się o losach patrona
          jednostki w początkowym okresie drugiej wojny światowej czytając treść
          z zamieszczoną na planszy. Na kolejnych z nich zostały zaprezentowano
          informacje dotyczące jednostek lotniczych, których tradycje
          odziedziczyły 23BLT i 1ELT. W związku z tym odwiedzając salę można
          poznać dzieje takich formacji jak 7 Eskadra Myśliwska im. Tadeusza
          Kościuszki, 19 Eskadra Myśliwska, 1 Pułk Lotniczy, Brygada Pościgowa,
          I/145 Dywizjon Myśliwski, 303 Dywizjon Myśliwski „Warszawski” im.
          Tadeusza Kościuszki, 316 Dywizjon Myśliwski „Warszawski”, 1 Pułk
          Lotnictwa Myśliwskiego „Warszawa”. Z jednej z pozostałych plansz można
          zasięgnąć wiadomości związanych z historią 23BLT i 1ELT. Zapoznanie
          się z treścią innej z nich przynosi otrzymanie solidnej dawki wiedzy
          na temat Polskiego Kontyngentu Wojskowego „Orlik”.
        </p>
        <p>
          Tworzący go żołnierze i oficerowie brali udział w misji Baltic Air
          Policing polegającej na ochronie przestrzeni powietrznej Litwy, Łotwy
          i Estonii. Wśród nich nie zabrakło przedstawicieli jednostek
          stacjonujących w lotniczej bazie znajdującej się tuż obok Mińska
          Mazowieckiego. - Żołnierze biorący udział w kontyngencie otrzymują
          pamiątkowe odznaczenie – Gwiazdę Załóg Lotniczych, które ustanowione
          zostało w dniu 4 maja 2012 roku. Na wstążce Gwiazdy Załóg Lotniczych
          nadanej żołnierzowi uczestniczącemu w PKW Orlik nakłada się okucie z
          napisem: „ORLIK 1” i odpowiednio – „ORLIK 3”, „ORLIK 6” i kolejne –
          czytamy w informacji zamieszczonej na planszy. Przykłady wspomnianych,
          okolicznościowych, przedmiotów stanowią fragment zbiorów
          zaprezentowanych w tym miejscu. W sali tradycji wyeksponowano również
          przykłady dawnego i współczesnego polowego i galowego umundurowania
          pilotów. Duże wrażenie wśród osób zwiedzających przestronne
          pomieszczenie wywołują ponadto kombinezony lotnicze.
        </p>
        <p>
          Ważne miejsce w prezentacji eksponatów zajmuje bogata kolekcja
          naszywek i wizerunków polskiego godła państwowego. Drugi z
          wymienionych zespołów został przekazany 1 Pułkowi Lotnictwa
          Myśliwskiego „Warszawa” z okazji siedemdziesięciopięciolecia
          utworzenia jednostki. Tymczasem w trakcie wizyty, która miała miejsce
          w maju 1995 roku w 1 PLM „Warszawa” wojenną odznakę Zastępcy Dowódcy 7
          Eskadry Myśliwskiej im. Tadeusza Kościuszki przekazał na rzecz pułku
          generał brygady pilot Witold Urbanowicz.
        </p>
        <p>
          Odpowiednio pokazana jest kolejnym istotnym eksponatem znajdującym się
          w sali tradycji. Co więcej w bardzo dobrze oświetlonych gablotach nie
          brakuje też innych, licznych, podobnych przedmiotów. W tych
          wystawienniczych meblach znalazło się również miejsce dla pamiątkowych
          talerzy, medali, statuetek, pucharów i kryształów. Ponadto umieszczono
          w nich modele samolotów i pojazdów kołowych. Zwiedzając omawiane
          wnętrze można również obejrzeć fotografie, na których uwieczniono
          codzienność bądź uroczyste momenty w życiu jednostki. Z portretu
          zamontowanego na jednej ze ścian spogląda natomiast na gości
          pomieszczenia patron 23 BLT. Portret ppłk. pil. Jana Zumbacha
          przekazał jej Tomasz Kajkowski z Muzeum 303 w Napoleonie. Dodajmy, że
          w sali tradycji można zobaczyć także elementy uzbrojenia samolotów
          bojowych, pułkowy sztandar, księgę pamiątkową, naścienne wizerunki
          orderu Virtuti Militari i myśliwca MIG 29 oraz godła jednostek
          lotniczych stacjonujących pod Mińskiem Mazowieckim.
        </p>
        <p>
          Aby zwiedzić salę tradycji 23BLT należy skontaktować się z jednostką –
          tel. 261553270.{' '}
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/minsk-mazowiecki/23blot/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/minsk-mazowiecki/23blot/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default MinskMazowiecki23BLOT
